<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        md="12"
      >
            <v-card>
                <v-card-title class="red--text">
                    GRADING
                </v-card-title>
                <v-card-subtitle>
                    Set your school results grading system
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <!-- Create /Update Form -->
                        <v-col cols="12" v-if="newform">
                            <v-form v-model="valid" ref="form">
                                <v-container>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-select
                                                v-model="form.grade"
                                                outlined
                                                :items="grades"
                                                :rules="[v => !!v || 'Grade Letter is required']"
                                                label="Pick a Grade Letter"
                                                required
                                            ></v-select>
                                        </v-col>                                        
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.lower_limit"
                                                label="Lower Limit"
                                                :rules="limitRules"
                                                type="number" onwheel="this.blur()"
                                                min="0"
                                                required
                                            ></v-text-field>
                                        </v-col> 
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.upper_limit"
                                                label="Upper Limit"
                                                :rules="limitRules"
                                                type="number" onwheel="this.blur()"
                                                min="0"
                                                required
                                            ></v-text-field>
                                        </v-col>   
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                            <v-text-field
                                                outlined
                                                v-model="form.remarks"
                                                label="Grading Remarks"
                                            ></v-text-field>
                                        </v-col>                                         
                                        <v-col
                                        cols="12"
                                        md="12"
                                        >
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="postRecord" v-if="!editBtn"
                                        >
                                            Create
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="success"
                                        class="mr-4" @click="updateRecord" v-else
                                        >
                                            Update
                                        </v-btn>
                                        <v-btn tile text color="secondary" @click="newform = false">
                                            <v-icon left>mdi-back</v-icon>
                                            Go Back
                                        </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-col>
                        <!-- Records -->
                        <v-col cols="12" v-else>
                            <info-loader-2 v-if="fetchingContent"/>
                            <v-row class="mb-5" v-else>
                                <v-col cols="12" sm="4" md="3" lg="2" v-for="(record, i) in records" :key="i">
                                    <v-card elevation="2" color="#F5F5F5">
                                        <v-card-title>
                                            {{record.grade}} ({{record.lower_limit}} - {{record.upper_limit}})
                                        </v-card-title>
                                        <v-card-actions>
                                            <!-- <v-btn @click="editRecord(record)" fab light x-small
                                            ><v-icon dark> mdi-pencil </v-icon>
                                            </v-btn> -->
                                            <v-btn @click="deleteRecord(record)" fab color="error" x-small
                                            ><v-icon dark> mdi-trash-can-outline </v-icon>
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-btn tile text color="success" @click="reset">
                                Add New Grade
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import HeaderVue from '../../components/Header.vue'
import InfoLoader2 from '../../components/InfoLoader2.vue';
  export default {
    name: 'Grades',
    components: {
        HeaderVue,
        InfoLoader2
    },
    mounted() {
       if (!this.authToken) {
           this.$router.push('login')
       }
       this.getRecords()
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        school:localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null,
        newform: false,
        editBtn: false,
        form:{},
        records:[],
        grades:['A+','A','B','C','D','E','F','P'],
        valid: true,
        limitRules: [
            v => !!v || 'Limit is required',
            v => /\b([0-9]|[1-9][0-9]|100)\b/.test(v) || 'Must be a valid integer',
        ],
        fetchingContent:true
    }),
    metaInfo: {
        meta: [
            { name: 'viewport', content: 'width=device-width,initial-scale=1.0' }
        ]
    },
    methods: {
        reset () {
            this.newform = true
            this.form = {}
        },
        getRecords () {
            axios.get(process.env.VUE_APP_BASEURL+'/grades?school_id='+this.school.school_id,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.records =  response.data.data  
                this.fetchingContent = false;          
            })
            .catch((error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem('authToken')
                }
                this.$toast.error(error.response ? error.response.data.message : 'APP is temporarily unavailable. Wait or Contact us!', {
                    timeout: 5000
                });
            })
            .finally(() => {
            });
        },
        postRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_BASEURL+'/grades?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        editRecord (record){
            this.newform = true
            this.form.id = record.grade_id
            this.form.grade = record.grade
            this.form.lower_limit = record.lower_limit
            this.form.upper_limit = record.upper_limit
            this.form.remarks = record.remarks
            this.editBtn = true
        },
        updateRecord () {
            let validation = this.$refs.form.validate()
            if(!validation) {
                return validation
            }
            let loader = this.$loading.show()
            axios.put(process.env.VUE_APP_BASEURL+'/grades/'+this.form.id+'?school_id='+this.school.school_id,this.form,
            {
                headers: 
                {
                    'Authorization': 'Bearer '+this.authToken
                }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                }); 
                this.getRecords()
                this.editBtn = false             
                this.newform = false             
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        deleteRecord (record) {
            if(confirm("Do you really want to delete?")){
                let loader = this.$loading.show()
                axios.delete(process.env.VUE_APP_BASEURL+'/grades/'+record.grade_id+'?school_id='+this.school.school_id+'&confirm=DELETE',{
                    headers: 
                    {
                        'Authorization': 'Bearer '+this.authToken
                    }
                })
                .then(response => {
                     this.$toast.success(response.data.message, {
                        timeout: 5000
                    }); 
                    this.getRecords()
                })
                .catch(error => {
                    this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                    });
                })
                .finally(() => {
                    loader.hide()
                });
            }
        }
    },
  }
</script>
